<template>
  <div class="search">
    <div class="tenant-box row">
      <div class="row small">
      <div class="top-content-left row">
        <h3 class="top-conten-title">Contacts</h3>
        <el-checkbox v-model="self" @change="selfFilter"  >Show Only My Contacts</el-checkbox >
      </div>
      <el-button class="primary-button small-btn"  @click="addTenants" >+ New Contact</el-button >
      </div>
      <div class="tanant-filter row">
        <el-input placeholder="Search by name, phone, email..." v-model="search" @keyup.enter.native="searchDate" class="filter-input" >
          <i slot="prefix" @click="searchDate" class="el-input__icon el-icon-search"></i>
        </el-input>
        <el-popover placement="bottom" width="200" trigger="click" style="margin: 0 10px 0 10px"  popper-class="popovers" >
          <div class="popover-contect">
            <el-checkbox-group v-model="type" @change="selectTypes">
              <el-checkbox class="checkout-box" v-for="(item, index) in contactType" :label="item.value" :key="index" >{{ item.label }}</el-checkbox >
            </el-checkbox-group>
          </div>
          <el-button class="btn-filter" slot="reference" >
            <p class="row">
              <img src="../../../assets/icon/ico-filter.svg" alt="" /><span class="filtyer-text" >Filter</span >
            </p>
          </el-button >
        </el-popover>
        <el-button class="light-blue-button clear-filter" @click="reset" >Clear Filter</el-button >
        <el-button class="primary-button clear-filter "  @click="addTenants" >+ New Contact</el-button >
      </div>
    </div>
  </div>
</template>
<script>
import { Minixs } from "../../../js/mixins";
import { Base64 } from "js-base64";
export default {
  mixins: [Minixs],
  data() {
    return {
      self: Base64.decode(sessionStorage.getItem("uersId"))==="10008"?false:true,
      search: "",
      type: [],
      contactType: [ { value: "tenant", label: "Tenant", },
        { value: "buyer", label: "Buyer", },
        { value: "homeowner", label: "Homeowner", },
        { value: "seller", label: "Seller", },
      ],
    };
  },
  methods: {
     reset() {
      this.search = "";
      this.self = Base64.decode(sessionStorage.getItem("uersId"))==="10008"?false:true;
      this.type = [];
      this.$store.commit("setContactsFilter", null);
      this.$emit("reset");
    },
    selectTypes() {
      this.$emit("selectTypes", this.type);
    },
    selfFilter() {
      this.$emit("selfFilter", this.self);
    },
    // 创建新租客
    addTenants() {
      this.$router.push({ name: "create-contact" });
    },
    searchDate(val) {
      this.$emit("searchFilter", this.search);
    },
  },
  created() {
    if (this.$store.state.contactsFilter != null) {
      this.self = this.$store.state.contactsFilter.self;
      this.search = this.$store.state.contactsFilter.search;
      this.type = this.$store.state.contactsFilter.type;
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../../css/button.scss";
@import "./popover.scss";
.search {
  margin: 30px 0 20px 0;
  .tenant-box {
    justify-content: space-between;
    align-items: flex-start;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    .top-content-left {
      width: 300px;
      .top-conten-title {
        font-size: 24px;
        font-family: "Roboto-Bold", sans-serif;
        margin: 0 20px 0 10px;
      }
      /deep/ .el-checkbox__label {
        color: #000;
        font-size: 14px;
        font-family: "Roboto-Regular", sans-serif;
      }
    }
    .small-btn{ display: none;}
    .tanant-filter {
      justify-content: flex-end;
      width: calc(100% - 320px);
      .filter-input {
        width: 460px;
        .el-icon-search {
          color: #678993;
        }
      }

      .btn-filter {
        margin: 0;
        padding: 0 20px;
        color: #38425b;
        background-color: #fff;
        .row {
          height: 38px;
          margin: 0;
          width: 70px;
          justify-content: space-between;
        }
      }
    }
  }
  @media (max-width: 1200px) {
    .tenant-box{
       display: block;
       .tanant-filter,.top-content-left{
         width: 100%;
       }
       .tanant-filter{
         margin-top: 15px;
         justify-content: flex-start;
       }
    }
  }
  @media (max-width: 992px) {
    .tenant-box{
      .small{-webkit-flex-wrap: wrap; flex-wrap: wrap;justify-content: space-between;}
      .top-content-left{ width: 370px;-webkit-flex-wrap: wrap; flex-wrap: wrap;}
      .small-btn{ display: block; margin-top: 10px;}
      .clear-filter,.filtyer-text{ display: none; }
      .tanant-filter { 
         justify-content: space-between;
        .filter-input{ width:calc(100% - 60px); }
        .btn-filter { padding: 0 15px; .row{ width: 100%; }}
      }
    }
  }
}
</style>