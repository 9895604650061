<template>
  <div class="tenants">
    <Top-Search @selectTypes="selectTypes" @selfFilter="selfFilter" @searchFilter="searchFilter" @reset="reset" ></Top-Search>
    <div class="row">
       <div class="show-number"> Show {{ offset + 1 }} - {{ offset + limit > total ? total : offset + limit }} of {{ total | currencyPrice("", "", "0") }} Contacts  </div>
       <el-button class="btn" @click="showAllContacts">Show All</el-button>
    </div>
    <div class="tenant_list">
      <Contact-Table v-loading="loading" :data="data" :total="total" @sort="sort"  @checked="checked" :limit="limit" @pagesChange="pagesChanged" :currentPage="currentPage" ></Contact-Table>
    </div>
    <mt-popup :modal="false" v-model="popupVisible" class="mt-popup" position="bottom">
      <el-radio-group class="row move-row">
        <div class="subset">
          <el-radio-button :label="1" @click.native.prevent="handleExportContacts">Export Contacts</el-radio-button>
        </div>
        <div class="subset">
          <el-radio-button :label="2" @click.native.prevent="handleEmailBtnClick">Email Contacts</el-radio-button>
        </div>
      </el-radio-group>
    </mt-popup>
    <div class="footer-download">
      <div style="text-align: center">
        <el-radio-group class="row">
          <div class="sheet_owner">
            <el-radio-button :label="1" @click.native.prevent="handleExportContacts">Export Contacts</el-radio-button>
          </div>
          <div class="sheet_owner">
            <el-radio-button :label="2" @click.native.prevent="handleEmailBtnClick">Email Contacts</el-radio-button>
          </div>
        </el-radio-group>
      </div>
    </div>
    <el-dialog :visible.sync="showEmailPopup"  title="Email Contacts">
      <div class="view">
        <p class="view_tip">Body</p>
        <el-input type="textarea" resize="none" :rows="10" v-model="body" ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="send" @click="sendEmailToConacts" :loading="fullscreenLoading" >Send</el-button >
      </span>
    </el-dialog>
  </div>
  <!-- -->
</template>

<script>
import { apiGetContacts, apiEmailToContact, apiExportContacts } from "../../API/api";
import { downloadFile } from "../../API/utils";
import TopSearch from "./Contact/ContactsTopSearch";
import ContactTable from "./Contact/ContactsTable";
import { Base64 } from "js-base64";
export default {
  components: { TopSearch, ContactTable },
  data() {
    return {
      flag: false,
      total: 1, // 总页数
      currentPage: 1, // 当前页数
      limit: 25, // 每页显示数量
      ordering: "", // 排序
      data: [],
      loading: false,
      contactFilter: { type: [], search: "", self: Base64.decode(sessionStorage.getItem("uersId"))==="10008"?false:true, ordering: "" },
      
      popupVisible: false,
      fullscreenLoading: false,
      showEmailPopup: false,
      body: "",
      checkData: [],
    };
  },
  computed: {
    offset() {
      return (this.currentPage - 1) * this.limit;
    },
    query() {
      // 查询参数
      return {
        type: this.contactFilter.type,
        limit: this.limit,
        offset: this.offset,
        search: this.contactFilter.search,
        self: this.contactFilter.self,
        ordering: this.contactFilter.ordering,
      };
    },
    fuzzyQuery() {
      return {
        limit: this.limit,
        offset: this.offset,
        self: Base64.decode(sessionStorage.getItem("uersId"))==="10008"?false:true
      };
    },
  },
  methods: {
    showAllContacts(){
      this.currentPage = 1;
       this.limit = this.total
        window.scrollTo(0,0)
      this.getTenants();
    },
    reset() {
      this.currentPage = 1;
      this.flag = Base64.decode(sessionStorage.getItem("uersId"))==="10008" ? false : true;
      this.contactFilter = { type: [], search: "", self: Base64.decode(sessionStorage.getItem("uersId"))==="10008"?false:true, ordering: "" };
      this.getTenants();
    },
    selfFilter(self) {
      this.currentPage = 1;
      this.flag = false;
      this.contactFilter.self = self;
      this.getTenants();
    },
    selectTypes(type) {
      this.currentPage = 1;
      this.flag = false;
      this.contactFilter.type = type;
      this.getTenants();
    },
    searchFilter(item) {
      this.currentPage = 1;
      this.flag = false;
      this.contactFilter.search = item;
      this.getTenants();
    },
    // 获取 Tenant 列表数据
    getTenants() {
      this.loading = true;
      this.urldata(this.flag ? this.fuzzyQuery : this.query);
      apiGetContacts(this.flag ? this.fuzzyQuery : this.query)
        .then((res) => {
          this.$store.commit("setContactsFilter", this.query);
          this.loading = false;
          this.data = res.results;
          this.total = res.count;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    pagesChanged(number) {
      this.currentPage = number;
      this.getTenants();
    },
    // 排序
    sort(val) {
      this.currentPage = 1;
      this.flag = false;
      if (val.order === "ascending") {
        this.contactFilter.ordering = val.prop;
      } else if (val.order === "descending") {
        this.contactFilter.ordering = "-" + val.prop;
      } else {
        this.contactFilter.ordering = "";
      }
      this.getTenants();
    },
    checked(item) {
      if(item.length!=0) this.popupVisible = true;
      else this.popupVisible = false
      this.checkData = item;
    },
    urldata(val) {
      let data = Object.assign({}, val);
      delete data.limit;
      delete data.offset;
      this.$router.push({ path: "/contacts", query: data });
    },
    async sendEmailToConacts() {
      console.log('call');
      if (this.checkData.length === 0) {
        this.$message.error("No properties selected");
        return;
      }
      if (this.body.trim() === "") {
        this.$message.error("Body can not be empty");
        return;
      }
      this.fullscreenLoading = true;
      
      apiEmailToContact({
        "to_emails": this.checkData.map(contact => contact.email1),
        "cc_emails": [],
        "email_subject": 'Conacts',
        "email_body": this.body,
        "attachments": []
      }).then((res) => {
        this.$message.success("Successfully sent Email to the contact!");
        this.body = "";
      }).catch((err) => {
        this.$message.error( err.response.data && err.response.data.error ? err.response.data.error.message : "Email send fail, please try again later.");
      }).finally((err) => {
        this.disable = false;
      });
        
      // apiEmailOwners({
      //   listings: this.$store.state.propertiesCheckeds.map((listing) => listing.listing_id),
      //   email_body: this.body.trim(),
      // }).then((res) => {
      //   this.$message.success("Successfully sent Emails to the selected owners!");
      //   this.showEmailPopup = false;
      //   this.body = "";
      //   this.fullscreenLoading = false;
      // }).catch((err) => {
      //   this.$message.error(err.response.data.detail);
      //   this.fullscreenLoading = false;
      // });
    },
    handleEmailBtnClick() {
      this.showEmailPopup = true;
    },
    handleExportContacts() {
      apiExportContacts({
        contact_ids: this.checkData.map(contact => contact.contact_id)
      }).then((res) => {
        downloadFile( res, 'contacts.csv',  "text/csv" );
        this.$message.success("Export contacts successful");
      }).catch((err) => {
        this.$message.error( "Export contacts fail, please try again later" );
      }).finally(() => {
        this.loadingBtn = false;
      });
    }
  },
  created() {
    if (this.$store.state.contactsFilter != null) {
      this.contactFilter = Object.assign({}, this.$store.state.contactsFilter);
      this.currentPage = this.$store.state.contactsFilter.offset / this.limit + 1;
    }
    this.getTenants();
  },
};
</script>


<style lang="scss" scoped>
@import "../../css/common.scss";
@import "../../css/dialog.scss";
@import "../../css/footerdownload.scss";
.tenants {
  .row{
    margin: 0px;
    align-items: center ;
  }
  .tenant_list {
    background-color: #fff;
    padding-bottom: 100px;
  }
  .show-number {
    text-align: left;
  }
  .btn{
    height: 30px;
    line-height: 30px;padding: 0 15px;margin-left: 20px;
    color: #fff;
    background-color: #678993;
    border-color: #678993;
    &:hover {
      background-color: rgb(76, 115, 127);
      color: #fff;
    }
  }
  .mt-popup{
    width: 100%;
    display: none;height: 150px;overflow: scroll;
    padding-top: 20px;
    z-index: 10;
    .move-row{-webkit-flex-wrap: wrap;flex-wrap: wrap;}
    .subset{width: calc(calc(100% - 80px) / 2);min-width: 200px; padding: 0 20px; margin:10px 0 0 0;
    /deep/ .el-radio-button{width: 100%;
      .el-radio-button__inner{color: #678993;border: 1px solid #678993; box-sizing: border-box; border-radius: 4px;width: 100%;}}
    }
  }

  .view {
    text-align: left;
    .view_tip {
      font-family: "Roboto-Regular", sans-serif;
      font-size: 20px;
      margin-bottom: 10px;
    }
  }

  /deep/ .el-dialog{width: 600px;}
  .pdf /deep/.el-dialog{width: 400px;}
  @media (max-width:992px) {
    /deep/ .el-dialog,.pdf /deep/.el-dialog{width: 90%;}
   .mt-popup{display: block ;}

  }
}
</style>
