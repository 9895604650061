<template>
  <div class="contact-box">
    <div class="table">
      <el-table :data="data" @row-click="skipDetail" 
        @selection-change="checked" :default-sort="orderContent" @sort-change="sort">
        <el-table-column class="selection" type="selection" width="45" ></el-table-column>
        <el-table-column label="NAME" prop="first_name" width="140" sortable="custom">
          <template slot-scope="scope">
            <span >{{ scope.row.first_name }} {{ scope.row.last_name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="AGENT" prop="user__first_name" min-width="100" sortable="custom">
          <template slot-scope="scope">
            <span>{{ scope.row.owner_first_name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="EMAIL ADDRESS" prop="email1" min-width="200" sortable="custom">
          <template slot-scope="scope">
            <span>{{ scope.row.email1 }}</span>
          </template>
        </el-table-column>
        <el-table-column label="PHONE" prop="cell_phone" min-width="120" sortable="custom">
          <template slot-scope="scope">
            <a style="color: #606266;" :href="'tel:' + scope.row.cell_phone">{{ scope.row.cell_phone }}</a>
          </template>
        </el-table-column>
        <el-table-column label="MAILING ADDRESS" min-width="150">
          <template slot-scope="scope">
            {{scope.row.street1}} {{scope.row.city}} {{scope.row.state}} {{scope.row.country}}
          </template>
        </el-table-column>
        <el-table-column label="CONTACT TYPE(s)" min-width="150">
          <template slot-scope="scope">
            <div v-for="(item, index) in scope.row.types" :key="index">
               <span  v-if="item!='buyer'" class="contact-type">{{ item | capitalize }} </span>
              <p v-else style="margin:0;">
                <span  v-if="scope.row.owner_id===$store.state.userInfo.user_id||$store.state.userInfo.is_admin === true" class="contact-type">{{ item | capitalize }}
               </span>
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="150" prop="sned_email">
          <template>
            <el-link class="sand" :underline="false" icon="el-icon-s-promotion" >Send Email</el-link>
          </template>
        </el-table-column>
        <el-table-column width="150" prop="create_lease">
          <template>
            <el-link :underline="false">+ Create Lease</el-link>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <Page :total="total" :pageSize="limit" @number="pagesChanged" :currentPage="currentPage"></Page>
  </div>
</template>
<script>
import Page from "../../common/Paging";
export default {
  props: ["data", "total","currentPage", "limit" ],
  components: { Page },
  data() {
    return {
      orderContent: { prop: "", order: "" }, // table默认的排序
    };
  },
  methods: {
    skipDetail(item,column){
       if(column.property==="sned_email"){
         this.$router.push("/contact/" + item.contact_id + "#send-email");
       }else if(column.property==="create_lease"){
         this.$store.commit("setLeaseParam", { tenant: item.contact_id, tenantName: item.first_name + " " + item.last_name, });
         this.$router.push({ name: "create-lease" });
       }else if(column.property != "cell_phone"){
         console.log("eee")
          this.$router.push({ name: "show-contact", params: { id: item.contact_id } });
       }
    },
    pagesChanged(val) {
      this.$emit("pagesChange", val);
    },
    // 排序
    sort(val) {
      this.$emit("sort", val);
    },
    checked(item) {
      this.$emit("checked", item);
    },
  },
  filters: {
    capitalize(value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
  mounted(){
    if (this.$store.state.contactsFilter != null && this.$store.state.contactsFilter.ordering
    ) {
      if (this.$store.state.contactsFilter.ordering.indexOf("-") != -1) {
        this.orderContent.prop = this.$store.state.contactsFilter.ordering.split("-")[1];
        this.orderContent.order = "descending";
      } else {
        this.orderContent.prop = this.$store.state.contactsFilter.ordering;
        this.orderContent.order = "ascending";
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../../css/table.scss";
.contact-box {
  /deep/ .table .el-table td {
    padding: 10px 0;
  }
  .contact-type {
    display: inline-block;
    padding: 3px 10px;
    background-color: #f4f9fc;
    border: 1px solid #e1e9ef;
    margin: 0 5px 5px 0;
  }
  .sand {
    color: #678993;
    /deep/ .el-icon-s-promotion {
      font-size: 20px;
    }
  }
  .create {
    color: #d4af37;
  }
}
</style>
